





























































.main_videos_wrapper {
  .main_youtube_html_render {
    // background: #11bfe3;
    .text-muted {
      text-align: right;
    }
    .card-category {
      text-align: right;
      list-style: none;
      li {
        text-align: right;
        position: relative;
        padding-right: 17px;
        text-align: right;
        &::before {
          position: absolute;
          content: "";
          background-color: #706f6f;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          right: 0;
          top: 7px;
        }
      }
    }
  }

  .personalize_title {
    &.is_rtl {
      display: flex;
      flex-direction: row-reverse;
      gap: 8px;
    }
  }
  .main_youtube_html_render_for_tasks {
    .row {
      flex-direction: row-reverse;
      ul {
        text-align: right;
        list-style: none;
        margin-left: 0 !important;
        padding-left: 0 !important;
        li {
          text-align: right;
          position: relative;
          padding-right: 17px;
          text-align: right;
          &::before {
            position: absolute;
            content: "";
            background-color: #706f6f;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            right: 0;
            top: 7px;
          }
        }
      }
    }
  }
  // youtube_html_container_cms Style
  .youtube_html_container_cms {
    min-height: 400px;
  }
}
